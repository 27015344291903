import request from '@/plugins/axios'

/** S 模型 **/

export const apiModelLists = (params: any) => request.get('/settings.Goodscopysid/lists', { params })
// 商户模型详情
export const apiDetail = (params: any) => request.get('/settings.Goodscopysid/detail', { params })
// 商户更改模型上下架
export const apiChangeStatus = (params: any) => request.post('/settings.Goodscopysid/is_show', params)
// 商户更改模型启用/禁用
export const apiChangeIsable = (params: any) => request.post('/settings.Goodscopysid/isable', params)
// 商户编辑模型
export const apiModelEdit = (params: any) => request.post('/settings.Goodscopysid/edit', params)

export const apiModelSort = (params: any) => request.post('/settings.Goodscopysid/sort', params)

export const apiModelSetToShop = (params: any) => request.post('/settings.Goodscopysid/adds', params)
// 同步模型类型
export const apiAXsynmodlestly = (params: any) => request.get('/settings.Goodscopy/asynmodlestly', { params })

//模型类型列表
export const apiModlestlylist = (params: any) => request.get('/settings.Goodscopy/modlestlylist', { params })

export const apiModelSetfactory = (params: any) => request.post('/settings.Goodscopy/setfactory', params)

export const apiAsyncsid = (params: any) => request.get('/settings.Goodscopysid/asyncsid', { params })

// 模型列表
export const apiModelList = (params: any) => request.get('/settings.Goodscopy/lists', { params })

// 同步模型
export const apiSynchronizat = (params: any) => request.get('/settings.Goodscopy/async', { params })

// 同步商户模型
export const apiSynchronizatStore = (params: any) => request.get('/settings.Goodscopy/asyncsubordinate', { params })

// 模型分类列表
export const apiCategoryList = (params: any) => request.get('/settings.Goodscopy/categoryList', { params })

// 模型详情
export const apiDetails = (params: any) => request.get('/settings.Goodscopy/detail', { params })

// 更改模型状态
export const apiChangestatus = (params: any) => request.post('/settings.Goodscopy/status', params)

// 编辑模型
export const apiModeledit = (params: any) => request.post('/settings.Goodscopy/edit', params)

// 删除模型
export const apiModelDelete = (params: any) => request.post('/settings.Goodscopy/del', params)

//模型材质
export const apiModleStufflist = (params: any) => request.get('/settings.Goodscopy/modlestufflist', { params })
//同步材质
export const apiAXsynmodleStuff = (params: any) => request.get('/settings.Goodscopy/asynmodlestuff', { params })
//同步模型弹窗
export const apiSettingsGoodsCopyRemoteModleList = (params: any) => request.get('/settings.Goodscopy/remotemodlelist', { params })